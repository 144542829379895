import React, { Fragment } from 'react'
import { DI } from 'modules'
import HelmetWrap from 'components/helmet-wrap'
import frontload from 'hoc/frontload'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import page from 'hoc/page'

import Component from 'components/component'
import SearchForm from 'components/search-form'
import { videosList, livesList, videosFilter, landing } from 'config/routes'

import { ActionCableConsumer } from 'components/action-cable-wrapper'

import DashboardSliderContainer from './dashboard-slider-container'
import DashboardSlider from './dashboard-slider'

import DashboardLiveContainer from './dashboard-live-container'
import DashboardLiveSlider from './dashboard-live-slider'

import DashboardHeadBlock from './dashboard-head-block'
import MylistModal from 'components/my-lists-modal'

import 'submodules/responsivestyles/src/less/dashboard.less'
import ReactGA from 'react-ga'
import HotBox from 'components/hot-box/hot-box'

export class Dashboard extends Component {
  static displayName = 'DashboardPage'

  static preload(props) {
    const slug = 'dashboard'
    const {
      searchParams: { ...params },
    } = props.common
    return Promise.all([
      props.actions.frontpages.getFrontPage(slug),
      props.actions.dashboard.getDashboard(params),
      props.actions.videos.getRelatedList(params),
    ])
  }

  state = {
    tagsCount: this.props.common.isMobile ? 3 : Infinity,
  }

  componentDidUpdate(prevProps) {
    const localeIsUpdated = this.props.common.locale !== prevProps.common.locale
    const searchIsUpdated =
      this.props.common.searchParams !== prevProps.common.searchParams
    const userIsUpdated =
      this.props.user.isSignedIn !== prevProps.user.isSignedIn

    if (localeIsUpdated || searchIsUpdated || userIsUpdated) {
      Dashboard.preload(this.props)
    }
  }

  componentDidMount() {
    if (this.props.user.isSignedIn) {
      this.props.actions.videos.getRelatedList(this.props.common.searchParams)
    }
  }

  setCurrentVideo = externalName => {
    this.setState({ externalName })
  }

  openMyListModal = () => {
    this.props.actions.common.openNewListModal()
  }

  closeMyListModal = title => {
    if (title && title.length) {
      ReactGA.event({
        category: this.props.location.pathname,
        action: 'Add to list',
        label: title,
      })
    }
    this.props.actions.common.closeNewListModal()
  }

  confirmMyListModal = title => {
    const { externalName } = this.state
    const video = {
      item_type: 'video_project',
      item_id: externalName,
    }
    const user_id = this.props.user.currentUser.payload.id

    this.props.actions.user.createList(user_id, title, [], [video]).then(
      () => {
        this.closeMyListModal(title)
      },
      this.props.actions.notifications.createNotification({
        type: 'success',
        children: this.props.common.strings['page.profile.menu.after_update'],
      })
    )
  }

  onReceivedWebsocket = msg => {
    this.props.actions.dashboard.wsStatusChangeEventDashboard(msg.data)
  }

  afterLazyHandler = () => {
    setTimeout(() => {
      try {
        window.Tags.addEventsToDashboardPage(true)
      } catch (e) { }
    }, 1000)
  }

  render() {
    const { strings } = this.props.common
    const data = this.props.dashboard.dashboard.payload
    const relatedVideoData = this.props.videos.related.payload

    const { banners } = data
    const tags = data.tags.slice(0, this.state.tagsCount)

    const meta_tag =
      this.props.frontpages.info.payload.meta_tag &&
      this.props.frontpages.info.payload.meta_tag

    return (
      <Fragment>
        <HelmetWrap {...(meta_tag || {})} page="dashboard" />

        <section className="content">
          <ActionCableConsumer
            channel="Content::LiveEventsChannel"
            onReceived={this.onReceivedWebsocket}
          />

          <div className="iwrap">
            <SearchForm />

            <DashboardHeadBlock items={banners} />

            <HotBox
              title={strings['page.dashboard.hotbox.title']}
              text={strings['page.dashboard.hotbox.text']}
              buttonName={strings['page.dashboard.hotbox.button']}
            />

            {relatedVideoData.length > 4 && (
              <DashboardSliderContainer
                title={strings['page.dashboard.top.title']}
                text={strings['page.dashboard.top.text']}
              >
                <DashboardSlider
                  key={this.props.videos.related.updatedAt}
                  items={relatedVideoData}
                  setCurrentVideo={this.setCurrentVideo}
                />
              </DashboardSliderContainer>
            )}
            <DashboardSliderContainer
              title={strings['page.dashboard.latest.title']}
              text={strings['page.dashboard.latest.text']}
              button={{
                text: strings['page.dashboard.latest.link_1'],
                link: videosList.getPath(),
              }}
            >
              <DashboardSlider
                items={data.feed}
                setCurrentVideo={this.setCurrentVideo}
              />
            </DashboardSliderContainer>
          </div>
          {data.live_events.length > 0 && (
            <DashboardLiveContainer
              title={strings['page.dashboard.lives.title']}
              text={strings['page.dashboard.lives.text']}
              button={{
                text: strings['page.dashboard.lives.all_lives'],
                link: livesList.getPath(),
              }}
            >
              <DashboardLiveSlider items={data.live_events} />
            </DashboardLiveContainer>
          )}
          <LazyLoadComponent afterLoad={this.afterLazyHandler}>
            <div className="iwrap" id="dashboard_lazy">
              {tags.map(item => (
                <DashboardSliderContainer
                  key={item.tag.id}
                  title={item.tag.name}
                  tag={item.tag}
                  button={{
                    text: `${strings['page.dashboard.tags.all']} ${item.tag.name} ${strings['page.dashboard.tags.videos']}`,
                    link:
                      item.tag.view_type === 'landing'
                        ? landing.getPath(null, item.tag.slug)
                        : videosFilter.getPath(
                          '',
                          item.tag.state === 'regular' ? 'tag' : 'category',
                          `${item.tag.id}-${item.tag.name}`
                        ),
                  }}
                >
                  <DashboardSlider
                    items={item.projects}
                    setCurrentVideo={this.setCurrentVideo}
                  />
                </DashboardSliderContainer>
              ))}
              {this.props.common.isMobile && data.tags.length > tags.length && (
                <div className="pagination">
                  <a
                    href="#"
                    className="btn-bord btn-bord--more"
                    onClick={ev => {
                      ev.preventDefault()
                      this.setState({
                        tagsCount: Infinity,
                      })
                    }}
                  >
                    {strings['page.dashboard.tags.show_more'] ||
                      'Load more tags'}
                  </a>
                </div>
              )}
            </div>
          </LazyLoadComponent>

          {this.props.common.newListModal && (
            <MylistModal
              close={this.closeMyListModal}
              confirm={this.confirmMyListModal}
              title={this.props.common.strings['page.my_lists.add_list_new']}
              placeholder={
                this.props.common.strings['page.my_lists.list.modal.list_name']
              }
            />
          )}
        </section>
      </Fragment>
    )
  }
}

export default page(
  DI([
    'dashboard',
    'videos',
    'lives',
    'frontpages',
    'common',
    'user',
    'notifications',
  ])(frontload(Dashboard, 'dashboard')),
  {
    pageName: 'dashboard',
  }
)