import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import { videosList } from 'config/routes'
import { Link } from 'components/link'
import List, { VideoItemAdapter } from 'components/list'

import { AEFilterPlain } from 'components/ae-filter'
import { withRouter } from 'react-router'
import PageLoading from 'components/page-loading'
import NotFoundPage from 'pages/content/not-found'
import page from 'hoc/page'

import Pagination from 'components/pagination'

import 'submodules/responsivestyles/src/less/account.less'
import 'submodules/responsivestyles/src/less/mylist.less'
import 'submodules/responsivestyles/src/less/all-events.less'

const PER_PAGE = 10

export class ListView extends Component {
  static preload(props) {
    const list_id = props.match.params.id

    if (list_id) {
      return Promise.all([
        props.actions.user
          .getListItems({
            list_id,
            page: 1,
            per_page: PER_PAGE,
            ...props.common.searchParams,
          })
          .then(() => {
            props.actions.common.wrapClass('profile--mylists')
          }),
      ])
    }
    return null
  }

  state = {
    isError: false,
  }

  componentDidMount() {
    this.currentList()
    ListView.preload(this.props)
    this.props.actions.common.wrapClass('profile--clear profile--mylists')
  }

  componentWillUnmount() {
    this.props.actions.common.wrapClass(null)
  }

  currentList = () => {
    this.props.actions.user
      .getActiveList(this.props.match.params.id)
      .catch(error => {
        if (error) {
          this.setState({ isError: true })
        }

        throw error
      })
  }

  componentDidUpdate(prevProps, _prevState) {
    if (
      prevProps.user.myLists.payload.lists !==
      this.props.user.myLists.payload.lists
    ) {
      this.currentList()
    }

    const localeIsUpdated = this.props.common.locale !== prevProps.common.locale

    const paramsIsUpdated =
      this.props.common.searchParams !== prevProps.common.searchParams

    if (paramsIsUpdated || localeIsUpdated) {
      ListView.preload(this.props)
    }
  }

  render() {
    const { strings } = this.props.common

    const { payload: data, isInited, pending } = this.props.user.activeList

    const listItems = (this.props.user.myListItems.payload || {}).lists || []
    const totalCount = (this.props.user.myListItems.payload || {}).count || 0

    const search = this.props.common.searchParams

    if (!isInited || pending) {
      return <PageLoading />
    }
    if (this.state.isError) {
      return <NotFoundPage />
    }

    return (
      <Fragment>
        <section className="profile-content">
          <div
            className={this.classList(
              'profile j-account',
              this.props.common.wrapClass
            )}
          >
            <div className="profile-main">
              <div className="profile-zone-intro">
                <div className="wrap wrap--mobile">
                  <div className="profile-side-button profile-side-button--many profile-side-button--mylist">
                    <AEFilterPlain />
                  </div>
                </div>
              </div>

              <div className="profile-zone-list">
                <div className="wrap wrap--mobile">
                  <span className="profile-zone__title">{data.title}</span>
                </div>
              </div>

              <div className="wrap wrap--mobile">
                {listItems && listItems.length > 0 ? (
                  <Fragment>
                    <div className="mylist-content">
                      <List withFilter>
                        {listItems.map(item => (
                          <VideoItemAdapter
                            key={item.id}
                            video={item.content}
                            listId={item.list_id}
                            videoId={item.id}
                          />
                        ))}
                      </List>
                    </div>

                    <Pagination
                      page={search.page}
                      perPage={PER_PAGE}
                      count={totalCount}
                    />
                  </Fragment>
                ) : (
                  <div className="nf">
                    <span className="nf__subtitle">
                      {strings['page.my_lists.no_video']}
                    </span>
                    <span className="nf__text">
                      {`${strings['page.my_lists.empty.left']} "${data.title}" ${strings['page.my_lists.empty.right']}`}
                    </span>
                    <span className="nf__text">
                      <Link to={videosList.getPath()}>
                        {strings['page.my_lists.explore']}
                      </Link>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default page(
  DI(['user', 'notifications', 'common'])(withRouter(ListView))
)
