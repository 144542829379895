import React from 'react'

import HelmetWrap from 'components/helmet-wrap'
import Component from 'components/component'
import { DI } from 'modules'
import frontload from 'hoc/frontload'
import page from 'hoc/page'
import InfinityFeed from 'components/infinity-feed'
import AEFilter from 'components/ae-filter'
import PageSubmenu from 'components/page-submenu'
import PageLoading from 'components/page-loading'
import { ActionCableConsumer } from 'components/action-cable-wrapper'

import List, { LiveItemAdapter } from 'components/list'
import { Group } from 'components/list/group'
import { DateTime } from 'luxon'
import NotFoundPage from 'pages/content/not-found'

import 'submodules/responsivestyles/src/less/all-events.less'

const PER_PAGE = 60

export class LivesList extends Component {
  get dataList() {
    switch (this.props.slug) {
      case 'recordings':
        return this.props.lives.listRecordings
      default:
        return this.props.lives.list
    }
  }

  static preload(props, isChangedLocale) {
    const slug = props.slug || 'events'
    const preloadList = [LivesList.loadListData(props, 1, PER_PAGE)]
    const {
      frontpages: {
        info: { payload: tag },
      },
    } = props

    if (tag.slug !== slug || isChangedLocale) {
      preloadList.concat([props.actions.frontpages.getFrontPage(slug)])
    }

    return Promise.all(preloadList)
  }

  static loadListData = (props, page, perPage) => {
    switch (props.slug) {
      case 'recordings':
        return props.actions.lives.getListRecordings(page, perPage)
      default:
        return props.actions.lives.getList(page, perPage)
    }
  }

  state = {
    page: 1,
    checkingFollow: false,
  }

  componentDidUpdate(prevProps) {
    const {
      common: { locale },
      user: { isSignedIn },
    } = this.props
    const localeIsUpdated = locale !== prevProps.common.locale

    if (localeIsUpdated) {
      LivesList.preload(this.props, true)
    } else if (isSignedIn && !prevProps.user.isSignedIn) {
      this.setState({ checkingFollow: true }, () => {
        LivesList.loadListData(this.props, 1, PER_PAGE).then(() => {
          setTimeout(() => {
            this.setState({ checkingFollow: false })
          }, 500)
        })
      })
    }
  }

  loadMore = () => {
    if (this.dataList.payload.length === this.state.page * PER_PAGE) {
      this.setState(
        state => ({
          page: state.page + 1,
        }),
        () => {
          LivesList.loadListData(props, this.state.page, PER_PAGE)
        }
      )
    }
  }

  onReceivedWebsocket = msg => {
    this.props.actions.lives.wsStatusChangeList(msg.data)
  }

  isAllowedAccess = () => {
    return (
      this.props.slug === 'events' ||
      (this.props.user.agreements.payload || []).some(
        agr =>
          agr.status === 'active' &&
          agr.recordings_access &&
          this.props.slug === 'recordings'
      )
    )
  }

  onStreamItemClick = event => {
    if (!this.props.user?.country?.name) {
      event.preventDefault()

      this.props.actions.notifications.createNotification({
        type: 'error',
        children: this.props.common.strings['page.events.counry_missing_error'],
      })
    }
  }

  renderItems() {
    const { checkingFollow } = this.state
    switch (this.props.common.listViewType) {
      case 'list': {
        const keyFormat = 'yyyyMMdd'
        const checkToday = date =>
          DateTime.local().toFormat(keyFormat) ===
          DateTime.fromISO(date).toFormat(keyFormat)
        const checkTomorrow = date =>
          DateTime.local().plus({ days: 1 }).toFormat(keyFormat) ===
          DateTime.fromISO(date).toFormat(keyFormat)

        const groups = this.dataList.payload.reduce((result, live) => {
          const key = DateTime.fromISO(live.start_at).toFormat(keyFormat)
          if (result.hasOwnProperty(key)) {
            result[key].push(live)
          } else {
            result[key] = [live]
          }
          return result
        }, {})

        const title = key => {
          const {
            common: { strings },
            user,
          } = this.props
          const date = DateTime.fromISO(key, { zone: user.timezone })

          if (checkToday(key)) {
            return `${strings['today']} · ${date.toFormat('MMMM d')}`
          } else if (checkTomorrow(key)) {
            return `${strings['tomorrow']} · ${date.toFormat('MMMM d')}`
          }
          return date.toFormat('MMMM d')
        }

        return (
          <InfinityFeed
            className="laes"
            onEnd={this.loadMore}
            enable={!this.dataList.pending}
          >
            {Object.keys(groups)
              .sort()
              .map(key => (
                <Group key={key} title={title(key)}>
                  <List>
                    {groups[key].map(item => (
                      <LiveItemAdapter
                        key={item.id}
                        live={item}
                        checkingFollow={checkingFollow}
                        onClick={this.onStreamItemClick}
                      />
                    ))}
                  </List>
                </Group>
              ))}
          </InfinityFeed>
        )
      }
      default: {
        return (
          <InfinityFeed
            className="ae-list"
            onEnd={this.loadMore}
            enable={!this.dataList.pending}
          >
            {this.dataList.payload.map(item => (
              <LiveItemAdapter
                key={item.id}
                live={item}
                checkingFollow={checkingFollow}
                onClick={this.onStreamItemClick}
              />
            ))}
          </InfinityFeed>
        )
      }
    }
  }

  render() {
    const submenu = this.props.menu.mainMenu.length
      ? this.props.menu.mainMenu[1].children
      : []
    const { meta_tag } = this.props.frontpages.info.payload

    if (!this.isAllowedAccess()) {
      return <NotFoundPage />
    }

    return (
      <section className="content">
        <ActionCableConsumer
          channel="Content::LiveEventsChannel"
          onReceived={this.onReceivedWebsocket}
        />

        <HelmetWrap {...(meta_tag || {})} page="broadcast" />

        <div className="iwrap">
          <PageSubmenu items={submenu} />
          <AEFilter livesCount={this.dataList.payload.length} />
          {this.renderItems()}
          {!this.dataList.isInited && <PageLoading />}
        </div>
      </section>
    )
  }
}

export default page(
  DI(['common', 'lives', 'menu', 'frontpages', 'user', 'notifications'])(
    frontload(LivesList, 'livesList')
  )
)
