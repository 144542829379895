import React from 'react'
import { Link } from 'components/link'
import { DI } from 'modules'
import Component from 'components/component'
import PropTypes from 'prop-types'

import { livesView, videosList, videoView } from 'config/routes'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export class DashboardBanner extends Component {
  static propTypes = {
    banner: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      breaking: PropTypes.bool.isRequired,
      tag_id: PropTypes.number,
    }),
    isActive: PropTypes.bool.isRequired,
  }

  state = {
    isLandingBannerViewType: false,
  }

  componentDidMount() {
    this.updateLandingBannerViewType()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.banner.tag_id !== this.props.banner.tag_id ||
      prevProps.dashboard.dashboard.payload.tags.length !==
        this.props.dashboard.dashboard.payload.tags.length
    ) {
      this.updateLandingBannerViewType()
    }
  }

  updateLandingBannerViewType() {
    const { dashboard, banner } = this.props
    const tagId = banner.tag_id
    const isLanding =
      dashboard.dashboard.payload.tags.find(({ tag }) => tagId === tag.id)?.tag
        ?.view_type === 'landing'
    this.setState({ isLandingBannerViewType: isLanding })
  }

  getLink = (banner, withHref) => {
    const href = withHref ? document.location.href : ''

    switch (banner.target_link_to) {
      case 'video_project': {
        return href + videoView.getPath('', banner.video_project_extid)
      }
      case 'live_event': {
        return href + livesView.getPath('', banner.live_event_extid)
      }
      case 'tag': {
        return this.state.isLandingBannerViewType
          ? banner.custom_url
          : href + videosList.getPath() + `?q[tags_id_in][]=${banner.tag_id}`
      }
      default: {
        return banner.custom_url
      }
    }
  }

  renderLink(props = {}) {
    const { banner } = this.props
    const url = this.getLink(banner, true)

    const onClick = ev => {
      if (window.ga) {
        window.ga('set', {
          dimension1: this.props.user.currentUser.payload.id,
          dimension2: banner.id,
          dimension3: banner.title,
          dimension4: this.getLink(banner, true),
          dimension5: new Date().getTime(),
        })
        window.ga('send', 'pageview')
      }
    }

    if (banner.custom_url) {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={url}
          {...props}
          onClick={onClick}
          data-action-type="banner"
        >
          {banner.title}
        </a>
      )
    } else {
      return (
        <Link to={url} {...props} onClick={onClick} data-action-type="banner">
          {banner.title}
        </Link>
      )
    }
  }

  render() {
    const { banner } = this.props
    return (
      <div
        className={this.classList(
          'dashboard-head-item',
          this.props.isActive && '_active'
        )}
      >
        <div className="dbh">
          <div
            className={this.classList(
              'dbh-img',
              banner.title && banner.title.trim().length === 0 && 'no-fade'
            )}
          >
            <LazyLoadComponent
              placeholder={
                <div className="dbh__img _loading">
                  <div className="_img-loader" />
                </div>
              }
            >
              {this.renderLink({
                className: 'dbh__img',
                style: {
                  background: `url(${
                    (banner.image?.small || banner.image || {}).url
                  })`,
                },
              })}
            </LazyLoadComponent>
            {banner.breaking && <span className="dbh__breaking">Breaking</span>}
          </div>
          <div className="dbh-content">
            {this.renderLink({
              className: 'dbh__link',
            })}
            <span className="dbh__title">{banner.title}</span>
            <div className="dbh-time dbh-time--not-live">
              <div className="dbh__time">{banner.country_name}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DI(['user', 'dashboard'])(DashboardBanner)
