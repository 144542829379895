import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import FilterContentType from './content-type'
import Form from 'common/components/form/new-form'
import DateFilter from 'pages/components/filter/date'
import FilterBlock from 'pages/components/filter/block'
import { IS_LANDING } from 'config'

const styleText = {
  fontSize: '13px',
  lineHeight: '1.54',
  color: 'rgb(190, 194, 195)',
  marginTop: '15px',
}

export class FilterMyContent extends Component {
  onChange = (ev, form) => {
    const params = {
      ...this.props.common.searchParams,
      ...form,
    }

    if (!form.q) {
      delete params.q
    }
    delete params.page

    this.props.actions.common.updateSearchParams(params, true)
  }

  onClickReset = ev => {
    ev.preventDefault()
    this.onChange(ev, {})
  }

  createNotifSuccess = text => {
    this.props.actions.notifications.createNotification({
      type: 'success',
      children: text,
    })
  }

  onSubmit = (ev, data) => {
    ev.preventDefault()

    this.props.actions.user.getOperationReport(data).then(resp => {
      this.createNotifSuccess(
        this.props.common.strings['page.my_content.export.success']
      )
      return resp
    })

    return false
  }

  render() {
    const { strings, searchParams } = this.props.common
    const { q = {} } = searchParams
    const isBtnDisabled = !this.props.user.myContentData.content.length
    return (
      <Fragment>
        <div className="vod-filter">
          <Form onChange={this.onChange} onSubmit={this.onSubmit} onlyWithValue>
            <FilterBlock title={strings['vods.filters.date.title']} openDefault>
              <DateFilter
                start={{
                  name: 'q[created_at_gteq]',
                  value: q.created_at_gteq,
                }}
                end={{
                  name: 'q[created_at_lteq]',
                  value: q.created_at_lteq,
                }}
              />
            </FilterBlock>

            <FilterBlock
              title={strings['vods.filters.content_type.title']}
              openDefault
            >
              <FilterContentType
                name="q[content_type]"
                value={q.content_type}
              />
            </FilterBlock>

            <div className="vod-filter-block vod-filter-block--not-spoiler">
              <a
                href="#"
                className={this.classList(
                  'text-button-reset',
                  !this.props.common.searchParams.q && '_disabled'
                )}
                onClick={this.onClickReset}
              >
                {strings['vods.filters.reset']}
              </a>
            </div>

            {!IS_LANDING && <div className="vod-filter-block vod-filter-block--not-spoiler">
              <div className="export-buttons" style={{ margin: '0px' }}>
                <div className="export-button">
                  <button
                    type="submit"
                    className="button button--small"
                    disabled={isBtnDisabled}
                  >
                    {strings['page.my_content.export.button']}
                  </button>
                </div>
              </div>
              <div className="export-text" style={styleText}>
                {strings['page.my_content.export.text']}
              </div>
            </div>}
          </Form>
        </div>
      </Fragment>
    )
  }
}

export default DI(['common', 'notifications', 'user'])(FilterMyContent)
