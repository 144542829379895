import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { DI } from 'modules'
import page from 'hoc/page'
import { Helmet } from 'react-helmet'

import SearchForm from 'components/search-form'
import DashboardSliderContainer from 'pages/content/dashboard/dashboard-slider-container'
import DashboardSlider from 'pages/content/dashboard/dashboard-slider'

import 'submodules/responsivestyles/src/less/dashboard.less'
import { getLocaleFromUrl } from 'helpers/strings'
import { IS_LANDING } from 'config'
import * as routes from 'config/routes'

export class NotFoundPage extends React.Component {
  componentDidMount() {
    if (!IS_LANDING) {
      this.props.actions.videos.getRelatedList({
        ...this.props.common.searchParams,
      })
    }
  }

  render() {
    const strings = this.props.common.strings
    const data = this.props.videos.related.payload
    const locale = getLocaleFromUrl(this.props.location.pathname)

    return (
      <section className="content not-found">
        <Helmet
          htmlAttributes={{
            status: 404,
          }}
        />
        <div className="iwrap">
          <div className="page-title">{strings['page.not_found.title']}</div>
          <div className="not-found-text">
            <p>{strings['page.not_found.text_1']} </p>
            <br />
            <p>
              {strings['page.not_found.text_3']}
              <a
                href={`mailto:${strings[IS_LANDING ? 'service.email': 'clientdesk.email']}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {strings[IS_LANDING ? 'service.email': 'clientdesk.email']}
              </a>
            </p>
            <br />
            {!IS_LANDING && <p>
              {strings['page.not_found.text_2']}{' '}
              <Link to="/">{strings['page.not_found.link']}</Link>{' '}
              {strings['page.not_found.text_4']}
            </p>}
          </div>
          <div
            style={{
              position: 'relative',
              marginBottom: '16px',
              marginTop: '24px',
            }}
          >
            {IS_LANDING ? (
              <SearchForm
                searchRoute={routes.landingDashboard}
                suggestsEnabled={false}
                isLanding
                visible
              />
            ) : (
              <SearchForm visible />
            )}

            {!IS_LANDING && (
              <div className="not-found-links buttons-group">
                <Link to={`/${locale}/videos`} className="button">
                  All Videos
                </Link>
                <Link to={`/${locale}/events`} className="button">
                  All Lives
                </Link>
              </div>
            )}
          </div>
          {!IS_LANDING && (
            <DashboardSliderContainer>
              <DashboardSlider items={data.feed} />
            </DashboardSliderContainer>
          )}
        </div>
      </section>
    )
  }
}

function NotFoundText(props) {
  const strings = props.common.strings
  return (
    <div className="not-found-page">
      <div className="page-title">{strings['page.not_found.title']}</div>
      <div className="not-found-text">
        <p>
          {strings['page.not_found.text_1']} {strings['page.not_found.text_2']}
          <Link to="/">{strings['page.not_found.link']}</Link>
        </p>
        <br />
        <p>
          {strings['page.not_found.text_3']}
          <a
            href={`mailto:${strings['service.email']}`}
            target="_blank"
            rel="noreferrer"
            rel="noopener noreferrer"
          >
            {strings['service.email']}
          </a>
        </p>
      </div>
    </div>
  )
}

export const NotFoundBlock = DI()(NotFoundText)
export default withRouter(page(DI(['videos'])(NotFoundPage)))
